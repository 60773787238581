import React, { useState, useEffect } from "react";
import { Card } from "../Card/Card";
import "./EventCard.css";
import classNames from "classnames";

import nakazawaUrl from "../assets/sushi_nakazawa.jpg";
import yoshinoUrl from "../assets/yoshino.jpeg";
import tockUrl from "../assets/tock.jpeg";

export const EventCard = ({ show, setModalState }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  const [saveStatus, setSaveStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSuccess = () => {
    setErrorMessage(null);
  };

  const onFailure = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const onAuthenticatedSuccess = () => {
    setIsAuthenticated(true);
    setErrorMessage(null);
  };

  useEffect(() => {
    fetch(`https://melanieandjimmygifts.com/.netlify/functions/event_get_2`)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error;
        }
      })
      .then((res) => {
        const id = res.id;
        setSelectedId(id);
        onSuccess(res);
      })
      .catch(() => onFailure("failed to fetch currently saved selection"));
  }, []);

  const EventForm = ({ onSuccess, onFailure }) => {
    const handleSubmit = (event) => {
      if (event.charCode === 13) {
        const answer = document.getElementById("secondAnswer").value;
        // fix this domain reference
        fetch(
          `https://melanieandjimmygifts.com/.netlify/functions/event_cards_2?passcode=${answer}`
        )
          .then((res) => {
            if (res.status !== 200) {
              throw Error;
            }
          })
          .then((res) => {
            onAuthenticatedSuccess(res);
          })
          .catch(() => onFailure("wrong passcode!"));
      }
    };

    return (
      <div className="EventForm" onSubmit={handleSubmit}>
        <label>Enter passcode from email:</label>
        <div className="EventFormPasscode">
          <input
            className="EventFormPasscode-input"
            type="text"
            id="secondAnswer"
            onKeyPress={handleSubmit}
          />
        </div>
      </div>
    );
  };

  const EventInner = ({ id, name, url }) => {
    const handleSaveStatus = () => {
      if (saveStatus && selectedId) {
        fetch(
          `https://melanieandjimmygifts.com/.netlify/functions/event_save_2`,
          {
            body: JSON.stringify({ restaurantId: selectedId }),
            method: "POST",
          }
        )
          .then((res) => {
            if (res.status !== 200) {
              throw Error;
            }
          })
          .then((res) => {
            onSuccess(res);
          })
          .catch(() => onFailure("failed to save selection, try again..."));
      }
      setSaveStatus(!saveStatus);
    };

    return (
      <div className="EventInner">
        {!isAuthenticated ? (
          <EventForm onSuccess={onSuccess} onFailure={onFailure} />
        ) : (
          <div className="Event-revealButton" onClick={handleSaveStatus}>
            {saveStatus ? "Save Selection" : "Edit Selection"}
          </div>
        )}
        {errorMessage && (
          <div className="Event-errorMessage">{errorMessage}</div>
        )}
      </div>
    );
  };

  const Restaurant = ({ id, name, time, url }) => {
    const handleSelect = () => {
      if (saveStatus) {
        setSelectedId(id);
      }
    };

    return (
      <div
        className={classNames("Restaurant", {
          "Restaurant-hoverable": saveStatus && id !== selectedId,
          "Restaurant-selected": id === selectedId,
        })}
        onClick={handleSelect}
      >
        <div
          className="Store-image"
          style={{ backgroundImage: `url(${url})` }}
        ></div>
        <div>{name}</div>
        <div className="TimeText">{time}</div>
      </div>
    );
  };

  return (
    <Card show={show} setModalState={setModalState}>
      <div className="EventContent">
        <div className="Message">
          There's so much to celebrate now. Happy holidays, happy birthday, and
          happy new year! Most of all, I'm really glad we're together so I can
          give this present to you in person.
        </div>
        <div className="RestaurantRow">
          <Restaurant
            id="nakazawa"
            name="Sushi Nakazawa"
            time="Jan 8 @ 7pm"
            url={nakazawaUrl}
          />
          <Restaurant
            id="yoshino"
            name="Yoshino"
            time="Jan 31 @ 8:30pm"
            url={yoshinoUrl}
          />
          <Restaurant
            id="tock"
            name="Tock Gift Card"
            time="$800 anytime"
            url={tockUrl}
          />
        </div>
        <EventInner />
      </div>
    </Card>
  );
};
