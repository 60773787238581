import React, { useState, useEffect } from "react";
import "./Card.css";

export const Card = ({ show, setModalState, children }) => {
  const onClick = () => {
    setModalState(null);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="Background">
      <div className="Card">
        {children}
        <div className="CloseButton" onClick={onClick}></div>
      </div>
    </div>
  );
};
