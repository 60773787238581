import React, { useState } from "react";
import { Card } from "../Card/Card";
import "./FinalCard.css";

import finalUrl from "../assets/final.jpg";
import goodbyeUrl from "../assets/goodbye.gif";

export const FinalCard = ({ show, setModalState }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [cardsData, setCardsData] = useState({});
  const [showDetails, setShowDetails] = useState({});

  const onSuccess = (data) => {
    setIsAuthenticated(true);
    setCardsData(data);
    setHasFailed(false);
  };

  const onFailure = () => {
    setHasFailed(true);
  };

  const StoreForm = ({ onSuccess, onFailure }) => {
    const handleSubmit = (event) => {
      if (event.charCode === 13) {
        const answer = document.getElementById("secondAnswer").value;
        // fix this domain reference
        fetch(
          `https://melanieandjimmygifts.com/.netlify/functions/final_card?passcode=${answer}`
        )
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              throw Error;
            }
          })
          .then((res) => {
            onSuccess(res);
          })
          .catch(() => onFailure());
      }
    };

    return (
      <div className="StoreForm" onSubmit={handleSubmit}>
        {/* <div style={{ fontWeight: "800" }}>Security questions:</div>
        <label>What city is your honeymoon in:</label>
        <input className="StoreForm-input" type="text" name="firstAnswer" /> */}

        <label>Enter passcode from email:</label>
        <div className="StoreForm-emailPasscode">
          <input
            className="StoreForm-input"
            type="text"
            id="secondAnswer"
            onKeyPress={handleSubmit}
          />
          {/* <div className="StoreForm-enter">submit</div> */}
          {/* <div className="StoreForm-submit" /> */}
        </div>
      </div>
    );
  };

  return (
    <Card show={show} setModalState={setModalState}>
      <div className="StoreContent">
        <div className="FinalStoreRow">
          <div
            className="Final-image"
            style={{ backgroundImage: `url(${goodbyeUrl})` }}
          ></div>
          <div className="FinalMessage">
            Happy first anniversary! There will be a few packages coming to your
            home. It's a bit bittersweet to say this will be the final card -
            been a fun time sharing these gifts with you :)
          </div>
        </div>
      </div>
    </Card>
  );
};
