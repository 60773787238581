import React, { useState } from "react";
import { Card } from "../Card/Card";
import "./KitchenCard.css";

import brightlandUrl from "../assets/brightland.jpeg";
import omsomUrl from "../assets/omsom.jpeg";
import wandpUrl from "../assets/wandp2.png";

export const KitchenCard = ({ show, setModalState }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [cardsData, setCardsData] = useState({});
  const [showDetails, setShowDetails] = useState({});

  const onSuccess = (data) => {
    setIsAuthenticated(true);
    setCardsData(data);
    setHasFailed(false);
  };

  const onFailure = () => {
    setHasFailed(true);
  };

  const StoreForm = ({ onSuccess, onFailure }) => {
    const handleSubmit = (event) => {
      if (event.charCode === 13) {
        const answer = document.getElementById("secondAnswer").value;
        // fix this domain reference
        fetch(
          `https://melanieandjimmygifts.com/.netlify/functions/kitchen_cards?passcode=${answer}`
        )
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              throw Error;
            }
          })
          .then((res) => {
            onSuccess(res);
          })
          .catch(() => onFailure());
      }
    };

    return (
      <div className="StoreForm" onSubmit={handleSubmit}>
        {/* <div style={{ fontWeight: "800" }}>Security questions:</div>
        <label>What city is your honeymoon in:</label>
        <input className="StoreForm-input" type="text" name="firstAnswer" /> */}

        <label>Enter passcode from email:</label>
        <div className="StoreForm-emailPasscode">
          <input
            className="StoreForm-input"
            type="text"
            id="secondAnswer"
            onKeyPress={handleSubmit}
          />
          {/* <div className="StoreForm-enter">submit</div> */}
          {/* <div className="StoreForm-submit" /> */}
        </div>
      </div>
    );
  };

  const Store = ({ id, name, url }) => {
    const handleReveal = () => {
      // Clear states
      if (showDetails) {
        setHasFailed(false);
      }
      setShowDetails({ ...showDetails, [name]: !showDetails[name] });
    };

    return (
      <div className="Store">
        <div
          className="Store-image"
          style={{ backgroundImage: `url(${url})` }}
        ></div>
        <div>{name}</div>
        <div className="Store-revealButton" onClick={handleReveal}>
          {`${showDetails[name] ? "Hide" : "Show"} Gift Code`}
        </div>
        {showDetails[name] && (
          <div>
            {!isAuthenticated ? (
              <StoreForm onSuccess={onSuccess} onFailure={onFailure} />
            ) : (
              <div className="Store-giftSummary">
                <div>{`Code: ${cardsData[id]["code"]}`}</div>
                <div>{`Balance: ${cardsData[id]["balance"]}`}</div>
              </div>
            )}
            {hasFailed && (
              <div className="Store-errorMessage">wrong passcode!</div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <Card show={show} setModalState={setModalState}>
      <div className="Message">
        After settling in a new home, it's time to take advantage of all your
        new appliances. Get some oils, spices, and cocktail mixers for your next
        meals. Never hurts to save some for leftovers!
      </div>
      <div className="StoreRow">
        <Store id="brightland" name="Brightland" url={brightlandUrl} />
        <Store id="omsom" name="Omsom" url={omsomUrl} />
        <Store id="wandp" name="W&P" url={wandpUrl} />
      </div>
    </Card>
  );
};
