import React from "react";
import { HomeCard } from "../cards/HomeCard";
import { KitchenCard } from "../cards/KitchenCard";
import { MelsCard } from "../cards/MelsCard";
import { JimmysCard } from "../cards/JimmysCard";
import { EventCard } from "../cards/EventCard";
import { CharityCard } from "../cards/CharityCard";
import { OutdoorsCard } from "../cards/OutdoorsCard";
import { FinalCard } from "../cards/FinalCard";

export const ModalManager = ({ modalState, setModalState }) => {
  const MODAL_MAP = {
    home: HomeCard,
    kitchen: KitchenCard,
    mels: MelsCard,
    jimmys: JimmysCard,
    event: EventCard,
    charity: CharityCard,
    outdoors: OutdoorsCard,
    final: FinalCard,
  };

  const Card = MODAL_MAP[modalState];
  if (!Card) {
    return null;
  }
  return <Card show={!!modalState} setModalState={setModalState} />;
};
