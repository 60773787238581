import React, { useState } from "react";
import "./App.css";
import { Image } from "./Image/Image";
import { ModalManager } from "./helpers/ModalManager";

import homeUrl from "./assets/home.jpeg";
import kitchenUrl from "./assets/kitchen.jpeg";
import jewelryUrl from "./assets/jewelry.jpeg";
import clothingUrl from "./assets/clothing.jpeg";
import diningUrl from "./assets/dining.jpeg";
import charityUrl from "./assets/charity.jpeg";
import outdoorsUrl from "./assets/outdoors.jpeg";
import mysteryUrl from "./assets/mystery.jpeg";

import wedding1Url from "./assets/wedding_1.jpeg";
import wedding2Url from "./assets/wedding_2.jpeg";
import wedding3Url from "./assets/wedding_3.jpeg";
import wedding4Url from "./assets/wedding_4.jpeg";
import wedding5Url from "./assets/wedding_5.jpeg";
import wedding6Url from "./assets/wedding_6.jpeg";
import wedding7Url from "./assets/wedding_7.jpg";
import wedding8Url from "./assets/wedding_8.jpg";

function App() {
  const [modalState, setModalState] = useState(null);

  const openModal = (value) => {
    setModalState(value);
  };

  const ClickHereText = () => {
    return (
      <div className="ClickHereText">
        <div>click</div>
        <div style={{ transform: "translate(0vw, -0.5vw)" }}>here</div>
        <div className="ClickHereText-arrow"></div>
      </div>
    );
  };

  const UnlockDateText = () => {
    return (
      <div className="UnlockDateText">
        <div>unlock</div>
        <div style={{ transform: "translate(0vw, -0.5vw)" }}>on this</div>
        <div style={{ transform: "translate(0vw, -1vw)" }}>date</div>
        <div className="UnlockDateText-arrow"></div>
      </div>
    );
  };

  return (
    <div className="App">
      <header className="AppHeader">
        <div className="ImageRow">
          <Image
            imageUrl={wedding1Url}
            width="10vw"
            height="12vw"
            translate="translate(2vw, 3vw) rotate(-20deg)"
            zIndex="0"
            unlocked
          />
          {/* <ClickHereText /> */}
          <Image
            imageUrl={homeUrl}
            title="08/06/2022"
            width="10vw"
            height="12vw"
            translate="translate(0vw, -1vw) rotate(-4deg)"
            zIndex="2"
            unlocked
            onClick={() => openModal("home")}
          />
          <Image
            imageUrl={wedding7Url}
            width="10vw"
            height="12vw"
            translate="translate(0vw, -1vw) rotate(5deg)"
            zIndex="1"
            unlocked
          />
          <Image
            imageUrl={kitchenUrl}
            title="08/20/2022"
            width="10vw"
            height="12vw"
            translate="translate(-1vw, -1vw) rotate(15deg)"
            zIndex="2"
            unlocked
            onClick={() => openModal("kitchen")}
          />
          {/* <UnlockDateText /> */}
          <Image
            imageUrl={wedding2Url}
            width="10vw"
            height="12vw"
            translate="translate(-2vw, -1vw) rotate(5deg)"
            zIndex="1"
            unlocked
          />
        </div>
        <div className="ImageRow">
          <Image
            imageUrl={wedding6Url}
            width="10vw"
            height="12vw"
            translate="translate(-4vw, 2vw) rotate(-5deg)"
            zIndex="1"
            unlocked
          />
          <Image
            imageUrl={jewelryUrl}
            title="10/06/2022"
            width="10vw"
            height="12vw"
            translate="translate(-2vw, 1vw) rotate(10deg)"
            zIndex="3"
            unlocked
            onClick={() => openModal("mels")}
          />
          <Image
            imageUrl={wedding3Url}
            width="10vw"
            height="12vw"
            translate="translate(-2vw, 1vw) rotate(-1deg)"
            zIndex="1"
            unlocked
          />
          <Image
            imageUrl={clothingUrl}
            title="10/20/2022"
            width="10vw"
            height="12vw"
            translate="translate(-2vw, -1vw) rotate(-15deg)"
            zIndex="3"
            unlocked
            onClick={() => openModal("jimmys")}
          />
          <Image
            imageUrl={wedding4Url}
            width="10vw"
            height="12vw"
            translate="translate(-1vw, -1vw) rotate(-1deg)"
            zIndex="1"
            unlocked
          />
          <Image
            imageUrl={diningUrl}
            title="11/26/2022"
            width="10vw"
            height="12vw"
            translate="translate(2vw, -1vw) rotate(25deg)"
            zIndex="1"
            unlocked
            onClick={() => openModal("event")}
          />
        </div>
        <div className="ImageRow">
          <Image
            imageUrl={charityUrl}
            title="02/06/2023"
            width="10vw"
            height="12vw"
            translate="translate(1vw, 1vw) rotate(-10deg)"
            zIndex="1"
            unlocked
            onClick={() => openModal("charity")}
          />
          <Image
            imageUrl={wedding8Url}
            width="10vw"
            height="12vw"
            translate="translate(1vw, 1vw) rotate(0deg)"
            zIndex="1"
            unlocked
          />
          <Image
            imageUrl={outdoorsUrl}
            title="04/06/2023"
            width="10vw"
            height="12vw"
            translate="translate(0vw, 2vw) rotate(20deg)"
            zIndex="1"
            unlocked
            onClick={() => openModal("outdoors")}
          />
          <Image
            imageUrl={wedding5Url}
            width="10vw"
            height="12vw"
            translate="translate(0vw, -1vw) rotate(15deg)"
            zIndex="1"
            unlocked
          />
          <Image
            imageUrl={mysteryUrl}
            title="08/06/2023"
            width="10vw"
            height="12vw"
            translate="translate(-2vw, 0vw) rotate(-10deg)"
            zIndex="2"
            unlocked
            onClick={() => openModal("final")}
          />
        </div>
        <ModalManager modalState={modalState} setModalState={setModalState} />
      </header>
    </div>
  );
}

export default App;
