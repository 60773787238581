import React from "react";
import classNames from "classnames";
import "./Image.css";

import lockUrl from "../assets/lock.png";

export const Image = ({
  imageUrl,
  title,
  width,
  height,
  translate,
  zIndex,
  opacity,
  unlocked,
  onClick,
}) => {
  return (
    <div
      className={classNames("Image", { ImageEnabled: !!onClick })}
      style={{
        width,
        height,
        transform: translate,
        zIndex,
        opacity,
      }}
      onClick={onClick}
    >
      <div
        className={classNames("InnerImage", { InnerImageEnabled: !!unlocked })}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {!unlocked && <img className="LockImage" src={lockUrl} />}
      </div>

      <div className="Text">
        <div>{title}</div>
      </div>
    </div>
  );
};
