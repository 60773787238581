import React, { useState } from "react";
import { Card } from "../Card/Card";
import "./CharityCard.css";

import donorsChooseUrl from "../assets/donorschoose.jpeg";

export const CharityCard = ({ show, setModalState }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [cardsData, setCardsData] = useState({});
  const [showDetails, setShowDetails] = useState({});

  const onSuccess = (data) => {
    setIsAuthenticated(true);
    setCardsData(data);
    setHasFailed(false);
  };

  const onFailure = () => {
    setHasFailed(true);
  };

  const StoreForm = ({ onSuccess, onFailure }) => {
    const handleSubmit = (event) => {
      if (event.charCode === 13) {
        const answer = document.getElementById("secondAnswer").value;
        // fix this domain reference
        fetch(
          `https://melanieandjimmygifts.com/.netlify/functions/charity_card?passcode=${answer}`
        )
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              throw Error;
            }
          })
          .then((res) => {
            onSuccess(res);
          })
          .catch(() => onFailure());
      }
    };

    return (
      <div className="StoreForm" onSubmit={handleSubmit}>
        {/* <div style={{ fontWeight: "800" }}>Security questions:</div>
        <label>What city is your honeymoon in:</label>
        <input className="StoreForm-input" type="text" name="firstAnswer" /> */}

        <label>Enter passcode from email:</label>
        <div className="StoreForm-emailPasscode">
          <input
            className="StoreForm-input"
            type="text"
            id="secondAnswer"
            onKeyPress={handleSubmit}
          />
          {/* <div className="StoreForm-enter">submit</div> */}
          {/* <div className="StoreForm-submit" /> */}
        </div>
      </div>
    );
  };

  const Store = ({ id, name, url }) => {
    const handleReveal = () => {
      // Clear states
      if (showDetails) {
        setHasFailed(false);
      }
      setShowDetails({ ...showDetails, [name]: !showDetails[name] });
    };

    return (
      <div className="Store">
        <div
          className="Store-image"
          style={{ backgroundImage: `url(${url})` }}
        ></div>
        <div>{name}</div>
        <div className="Store-revealButton" onClick={handleReveal}>
          {`${showDetails[name] ? "Hide" : "Show"} Gift Code`}
        </div>
        {showDetails[name] && (
          <div>
            {!isAuthenticated ? (
              <StoreForm onSuccess={onSuccess} onFailure={onFailure} />
            ) : (
              <div className="Store-giftSummary">
                <div>{`Code: ${cardsData[id]["code"]}`}</div>
                <div>{`Balance: ${cardsData[id]["balance"]}`}</div>
              </div>
            )}
            {hasFailed && (
              <div className="Store-errorMessage">wrong passcode!</div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <Card show={show} setModalState={setModalState}>
      <div className="StoreContent">
        <div className="CharityStoreRow">
          <Store id="charity" name="Donors Choose" url={donorsChooseUrl} />
          <div className="CharityMessage">
            It's so amazing to see you volunteer your time with kids. Hope you
            can further your impact on children's education by choosing a
            classroom to support. Go to this website to redeem:
            <a href="http://www.donorschoose.org/redeem" target="_blank">
              {" donorschoose.org/redeem"}
            </a>
          </div>
        </div>
      </div>
    </Card>
  );
};
